import React, { useCallback } from 'react'
import { Button } from '@shared/components/button'
import { TextField } from '@shared/components/text-field'
import { usePhoneMask } from '@shared/hooks/use-phone-mask'
import { Paths } from '@shared/menu-items/paths'
import { getFullLinkByPath } from '@shared/pipes/dom'
import cx from 'classnames'
import { FormikProvider } from 'formik'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({
	form,
	isSubmitted,
	isSubmitting,
	buttonText: buttonTextHandler,
	formId,
	email,
	renderControls,
	simpleInputs,
	placeholders = ['Фамилия, имя, отчество', 'Номер телефона', 'Электронная почта'],
}: IStateProps) => {
	const { mask, maskChar, beforeMaskedValueChange } = usePhoneMask()

	const buttonText = buttonTextHandler
		? buttonTextHandler
		: (isSubmitted) => (isSubmitted ? 'Отправлено' : 'Отправить')

	const disableFields = isSubmitted || isSubmitting
	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault()
			form.handleSubmit()
		},
		[form.handleSubmit]
	)

	return (
		<FormikProvider value={form}>
			<form onSubmit={handleSubmit} id={formId}>
				<div role={simpleInputs ? 'simple-input-wrap' : 'input-wrap'}>
					<TextField
						required
						disabled={disableFields}
						name='name'
						label={placeholders[0]}
						placeholder='Имя'
						simpleInputs={simpleInputs}
					/>
				</div>
				<div role={simpleInputs ? 'simple-input-wrap' : 'input-wrap'}>
					<TextField
						required
						disabled={disableFields}
						name='phone'
						label={placeholders[1]}
						mask={mask}
						maskChar={maskChar}
						beforeMaskedValueChange={beforeMaskedValueChange}
						placeholder='Телефон'
						simpleInputs={simpleInputs}
					/>
				</div>
				{email && (
					<div role={simpleInputs ? 'simple-input-wrap' : 'input-wrap'}>
						<TextField
							required
							disabled={disableFields}
							name='email'
							label={placeholders[2]}
							placeholder='Email'
							simpleInputs={simpleInputs}
						/>
					</div>
				)}
				{typeof renderControls === 'function' ? (
					renderControls({ isSubmitted, submitButtonText: buttonText(isSubmitted), disableFields })
				) : (
					<>
						<div className={styles.submitButtonWrap}>
							<Button
								type='submit'
								disabled={disableFields}
								className={cx(styles.button, { [styles.submittedButton]: isSubmitted })}
							>
								{buttonText(isSubmitted)}
							</Button>
						</div>
						<div className={styles.privacy}>
							Нажимая кнопку, вы даете согласие на обработку&nbsp;
							<a
								className='main-link'
								target='_blank'
								rel='noopener noreferrer nofollow'
								href={getFullLinkByPath(Paths.agreement)}
							>
								персональных&nbsp;данных
							</a>
						</div>
					</>
				)}
			</form>
		</FormikProvider>
	)
}
