import { useCallback } from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { Autocomplete } from '@shared/components/autocomplete'
import { Button } from '@shared/components/button'
import { LoanFormCard } from '@shared/components/forms/new-loan/components/card'
import { LoanFormHint } from '@shared/components/forms/new-loan/components/hint'
import { TextField } from '@shared/components/text-field'
import { usePhoneMask } from '@shared/hooks/use-phone-mask'
import { Paths } from '@shared/menu-items/paths'
import { getFullLinkByPath } from '@shared/pipes/dom'
import Safety from '@shared/svg/safety.svg'
import { FormikProvider } from 'formik'

import { IStateProps } from './model'

import styles from './styles.module.scss'

export const View = ({
	extraHint,
	formik,
	purposes,
	fioAutocompleteOptions,
	handleChangeFioAutocompleteQuery,
}: IStateProps) => {
	const { mask, maskChar, beforeMaskedValueChange } = usePhoneMask()

	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault()
			formik.handleSubmit()
		},
		[formik.handleSubmit]
	)

	return (
		<FormikProvider value={formik}>
			<LoanFormCard className={styles.root}>
				<form onSubmit={handleSubmit} id={AnchorsOfForms.loanStep1}>
					<LoanFormHint
						Icon={Safety}
						value={<>+10% вероятности одобрения заявки за&nbsp;заполнение поля ФИО</>}
					/>
					{extraHint && <p className={styles.extraHint}>{extraHint}</p>}
					<div role='input-wrap'>
						<Autocomplete
							label='Фамилия, имя, отчество'
							freeSolo
							autoSelect
							blurOnSelect
							name='fio'
							inputRequired
							options={fioAutocompleteOptions}
							onInputChange={handleChangeFioAutocompleteQuery}
						/>
					</div>
					<div role='input-wrap'>
						<Autocomplete
							label='Цель займа'
							blurOnSelect
							name='purpose'
							inputRequired
							options={purposes}
						/>
					</div>
					<div role='inputs-wrap-2'>
						<div role='input-wrap'>
							<TextField
								required
								name='phone'
								label='Номер телефона'
								mask={mask}
								maskChar={maskChar}
								beforeMaskedValueChange={beforeMaskedValueChange}
							/>
						</div>
						<div role='input-wrap'>
							<TextField required name='email' label='Электронная почта' />
						</div>
					</div>
					<div className={styles.controls}>
						<Button type='submit' className={styles.submitButton}>
							Далее
						</Button>
						<div className={styles.privacy}>
							Нажимая кнопку, вы даете согласие&nbsp;
							<a
								className='main-link'
								target='_blank'
								rel='noopener noreferrer nofollow'
								href={getFullLinkByPath(Paths.agreement)}
							>
								на обработку персональных данных
							</a>
						</div>
					</div>
				</form>
			</LoanFormCard>
			<div className={styles.extra}>* Не является публичной офертой</div>
		</FormikProvider>
	)
}
