import React, { PropsWithChildren } from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { Button } from '@shared/components/button'
import { SimplePersonForm } from '@shared/components/forms/shared/simple-person-form'
import { Modal } from '@shared/components/modal'
import { Paths } from '@shared/menu-items/paths'
import { getFullLinkByPath } from '@shared/pipes/dom'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({
	title = (
		<>
			Узнайте больше <br /> об инвестициях
		</>
	),
	subtitle = <>Мы&nbsp;свяжемся с&nbsp;вами в&nbsp;течение 30&nbsp;минут</>,
	buttonText = 'Получить консультацию',
	showModal,
	onModalClose,
	onSubmitForm,
	onStartSubmitForm,
}: PropsWithChildren<IStateProps>) => (
	<Modal show={showModal} onClose={onModalClose}>
		<Modal.Header>
			<h2>{title}</h2>
			<h4 className={styles.hint}>{subtitle}</h4>
		</Modal.Header>
		<Modal.Body>
			<div className={styles.modalContent}>
				<SimplePersonForm
					email={false}
					onSubmit={onSubmitForm}
					onSubmitStart={onStartSubmitForm}
					formId={AnchorsOfForms.investorPopup}
					buttonText={(isSubmitted) => (isSubmitted ? 'Отправлено!' : (buttonText as string))}
					renderControls={({ submitButtonText, isSubmitted, disableFields }) => (
						<div className={styles.formControls}>
							<div className={styles.privacy}>
								Нажимая кнопку, вы даете согласие на обработку&nbsp;
								<a
									className='main-link'
									target='_blank'
									rel='noopener noreferrer nofollow'
									href={getFullLinkByPath(Paths.agreement)}
								>
									персональных данных
								</a>
							</div>
							<div className={styles.submitButtonWrap}>
								<Button
									type='submit'
									disabled={disableFields}
									className={cx(styles.button, { [styles.submittedButton]: isSubmitted })}
								>
									{submitButtonText}
								</Button>
							</div>
						</div>
					)}
				/>
			</div>
		</Modal.Body>
	</Modal>
)
